.addSubMenuPrice > div {
    height: 100%;
  }
.imgSection{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
    .imgTitle{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #959595;
    }
    .imgHolder{
        display: flex;
        flex-direction: row;
        gap: 10px;
        .modalImgProd{
            width: 86px;
            height: 85px;
            border-radius: 4px;

        }
        .changeImage{
            border: 1px dashed  #C7C7C7;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 10px;
            position: relative;
            .imgIcon{
                width: 44px;
                height: 44px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                // background-color: #EEEAE9;
                color: #F0522B;
            }
            .imgContentHolder{
                display: flex;
                flex-direction: column;
                gap: 5px;
                .changeLabel{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: 0em;
                    color: #F0522B;
                    text-decoration: none;
                }
                .changeDesc{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0em;
                    color: #B5B5B5;
                }
            }
        }
    }
}
#AppRestoForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .head-modal{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title{
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            color: #000000;
        }
        .hideMenu{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
            text-decoration: none;
            color: #575757;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .btn_action{
            button{
                margin: 5px 0;
            }
            button.MuiButton-outlined{
                color: #F0522B;
                border-color: #F0522B;
            }
            button.MuiButton-contained {
                background-color: #F0522B;
                box-shadow: unset;
            }
            button.MuiButton-contained:hover,
            button.MuiButton-outlined:hover{
                color: #FFF;
            }
        }
        .imgSection{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .imgTitle{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #959595;
            }
            .imgHolder{
                display: flex;
                flex-direction: row;
                gap: 10px;
                .modalImgProd{
                    width: 86px;
                    height: 85px;
                    border-radius: 4px;

                }
                .changeImage{
                    border: 1px dashed  #C7C7C7;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    padding: 10px;
                    .imgIcon{
                        width: 44px;
                        height: 44px;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // background-color: #EEEAE9;
                        color: #F0522B;
                    }
                    .imgContentHolder{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .changeLabel{
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 15px;
                            letter-spacing: 0em;
                            color: #F0522B;
                            text-decoration: none;
                        }
                        .changeDesc{
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0em;
                            color: #B5B5B5;
                        }
                    }
                }
            }
        }
        .inputField{
            border: 0px;
            width: 100%;
            height: 48px;
            padding: 8px 16px;
            border-radius: 8px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            background-color: #EEEAE9;
            color: #000;
        }
        .inputsHolder{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .inputIconHolder{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                .dollarIcon{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    color: #959595;
                }
            }
        }
        .textAreaHolder{
            display: flex;
            flex-direction: column;
            gap: 5px;
            label {
                padding-right: 5px;
                align-self: flex-end;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0px;
                color: #C0C0C0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .content{
            
        }
    }
}