#AppRestoBar_1{
    width: 100%;
    background-color: #380306;
    padding-top: 20px;
    // padding-bottom: 20px;
    .container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        .logo_container{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            .logoImg{
                width: 50px;
                height: 50px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
       
        .profileHolder{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            .title{
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: left;
                color: #ffffff;
            }
            .btnLogout{
                padding:  5px;
                background-color: #F0522B;
                font-family: Inter;
                font-size: 10px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #ffffff;
                text-transform: capitalize;
            }
        }
    }

    .container_list{
        display: flex;
        align-items: center;
        color: white;
        justify-content: flex-start;
        padding-left: 5px;
        padding-right: 15px;
        .individual_list{
            font-size: 14px;
            padding-bottom: 7px;
            margin-left: 20px;
        }
        .individual_list.active{
            
            border-bottom: 4px solid #F0522B;
        }
    }
}