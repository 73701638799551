.app_menu_container{
    // width: 282px;
    width: 30%;
    max-height: 92px;
    height: 92px;
    background-color: white;
    border-radius: 20px;
    // border: 1px solid rgb(255, 136, 0);
    display: flex;
    position: relative;
    margin-bottom: 18px;

    @media (max-width:767px) {
        width: 45%;
    }
    @media (max-width:540px) {
        width: 95%;
    }


    .best_seller_badge{
        position: absolute;
        top: -10px;
        right: 10px;
        // background-image: linear-gradient(#FCAD48,#F0522B);
        width: 64px;
        text-align: center;
        font-size: 8px;
        color: white;
        padding: 3px;
        border-radius: 10px;
    }

    .app_menu_container_left{
        width: 30%;
        height: 100%;
        border-radius: 20px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }
    .app_menu_container_right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        width: 70%;

        .menu_title{
            font-size: 10px;
            font-weight: 600;   
            margin-bottom: 2px;
        }
        .menu_desc{
            font-size: 8px;
            color: #606060;
            font-weight: 400;
            margin-bottom: 2px;
            line-height: 8px;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            max-width:100%;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden
        }
        .duration_container{
            display: flex;
            align-items: center;
            margin-top: 2px;
            margin-bottom: 2px;
            .menu_duration{
                font-size: 8px;
                color: #606060;
                font-weight: 400;
                margin-left: 5px;
                // margin-bottom: 2px;
            }
        }
       
        .menu_price{
            font-size: 12px;
            font-weight: 700;
            color: #F0522B;
        }
    }

}

@media (max-width:440px) {
    .app_menu_container{
        width: 100%;
    }
}
