#appMenuBarList{
    width: 100%;
    overflow-y: auto;
    .container{
        display: flex;
        gap: 10px;
        width: 2000px;
        .menuHolder{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 30px;
            border-radius: 5px;
            .menuBarList{
                text-decoration: none;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                color: #393939;
            }
        }
        
    }
}