#AppRestoCard{
    width: 100%;
    .container{
        display: flex;
        position: relative;
        max-width: 980px;
        .image{
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            width: 20%;
            max-height: 100px;
        }
        .cardItem{
            width: 80%;
            min-height: 150px;
            background-color: #ffffff;
            padding: 10px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .headTitle{
                    font-family: Montserrat;
                    font-size: 8px;
                    font-weight: 600;
                    line-height: 8px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    padding: 5px 10px;
                    border-radius: 6px;
                    background-image: linear-gradient(to right, #FCAD48, #F0522B);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .iconHandle{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .iconImg{
                        width: 12px;
                        height: 12px;
                    }
                    label {
                        font-family: Montserrat;
                        font-size: 8px;
                        font-weight: 600;
                        line-height: 14px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #000000;
                        text-transform: capitalize;
                    }
                }
            }
            .cardTitle{
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #000000;
            }
            .cardPrice{
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                color: #000000;
            }
            .footer{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 10px;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    gap: 4px;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0px;
                    text-align: center;
                }
                .edit{
                    color: #1480FF;
                }
                .delete{
                    color: #FF1414;
                }
                .hideMenu{
                    color: #575757;
                }
            }
        }
        .grayOut{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(254, 254, 254, 0.6);
        }
        
    }
    @media screen and (max-width: 480px) {
        .container{
            .image{
                max-height: 60px;
            }
            .cardItem{
                gap: 10px;
            }
        }
    }
}