.ReservationPage_container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #e9e9e9;
    min-height: 90vh;
    .reservation_list{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 15px;
    }
    .card_main_cont{
       display: flex;
       gap: 20px;
       flex-wrap: wrap;
       @media (max-width:767px) {
        justify-content: center;
      }
      .card{
        width: 100%;
        max-width: 450px;
        background-color: white;
        border-radius: 7px;
        min-height: 100px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 2px 7px rgb(223, 220, 220);
        position: relative;
        // margin-bottom: 20px;
        @media (max-width:840px) {
            max-width: 48%;
        }
        @media (max-width:767px) {
          max-width: 500px;
       }

        @media (max-width:540px) {
          width: 100%;
        }
        .cont{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .label{
                font-weight: 400;
                color: #787878;
                min-width: 120px;
            }
            .value{
                margin-left: 10px;
                font-weight: 600;
                max-width: 50%;
                color: black;
                word-wrap: break-word; /* Add this line */
                text-transform: capitalize;
            }
        }
      
    }
    }
  

      
}

.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 750px;
    width: 100%;
    justify-self: center;
  }
  
  .modal-content {
    // padding: 20px;
    animation: slideUp 0.5s all ease;

    @media(max-width:750px) {
      bottom: 0;
    }
    
    .table_list_container{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        .table_card{
            width: 90px;
            height: 90px;
          
            // margin-right: 20px;
            // margin-bottom: 20px;
            background-color: #EEEAE9;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .table_text{
                font-size: 16px;
                font-weight: 600;
            }
        }

    }
    .label_cont{
        display: flex;
        .lab_1{
            min-width: 100px;
            color: #BCBCBC;
            margin-right: 40px;
        }
        .lab_2{
            color: #FFFFFF;
            font-weight: 600;
        }
    }
    
  }
  
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    25% {
      opacity: 0.5;
      transform: translateY(25%);
    }
    75% {
      opacity: 0.75;
      transform: translateY(75%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }