.landing_page_container{
    width: 100%;
    max-width: 1929px;
    background-image: url("../../../assets/images/crimson.png");   
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    @media (max-width:840px) {
        justify-content: flex-start;
    }
    .logo{

        width: 254px;
        height: 150px;
        @media (max-width:840px) {
            
            margin-top: 100px;
        }

        @media (max-width:640px) {
            width: 134px;
            height: 60px;
        }

        background-image: url("../../../assets/images/logo1.png");
        background-size: contain;
        background-repeat: no-repeat;
      
    }
    .sucess_login{
        font-weight: 600;
        font-size: 44px;
        line-height: 36px;
        margin-top: 30px;
        margin-bottom: 50px;
        color: #6BFE26;
        @media (max-width:640px) {
            margin-top: 40px;
           margin-bottom: 40px;
           font-size: 24px;
        }
    }
    .staff_info{
        display: flex;
        flex-direction: column;
        align-items: center;
        .staff_name{
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            text-transform: capitalize;
            line-height: 36px;
        }
        .staff_id{
            font-weight: 700;
            font-size: 36px;
            color: #F0522B;
            text-transform:uppercase;
            line-height: 38px;
        }
        .staff_btn{
            width: 117px;
            height: 40px;
            margin-top: 20px;
            background-color: #6B3D1C;
            color: white;
        }
    }
    .submit_btn{
        margin-top: 40px;
        width: 300px;
        @media (max-width:640px) {
            position: absolute;
            bottom:0;
            width: 100%; 
        }
       
        display: flex;
        justify-content: center;
        .btn{
            width: 90%;
            margin-bottom: 20px;
            color: #FFFFFF;
            text-transform: capitalize;
            background-color: #F0522B;

        }
    }
}