#AppFilter{
    width: 100%;
    .container{
        display: flex;
        justify-content: space-between;
        .sortHolder{
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            position: relative;
            p{
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0px;
                text-align: left;
                color: #A0A0A0;
            }
            .navigation{
                border: 0px;
                display: flex;
                align-items: center;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0px;
                color: #000000;
                &:hover{
                    background-color: unset;
                }
            }
            .listHolder{
                position: absolute;
                top: 20px;
                right: -5px;
                background-color: #ffffff;
                width: 150px;
                height: 110px;
                padding: 10px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                a{
                    text-decoration: none;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 0px;
                    border-bottom: 1px solid #cccccc;
                    padding-bottom: 10px;
                    color: #000000;
                }
            }
        }
    }
}