.appDropSearch{
    background-color: #eeeae9;
    border-radius: 10px;
    width: 100%;
    fieldset{
        border-color: #FFF;
        
    }
}
#AppDropDown{
    width: 100%;
    .navigation{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0px;
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        border-radius: 8px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        background-color: #EEEAE9;
        color: #959595;
        &:hover{
            background-color: #EEEAE9;
        }
        .selectHolder{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }
    }
    .listHolder{
        display: flex;
        flex-direction: column;
        background-color: #EEEAE9;
        padding: 2px;
        gap: 2px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
        .dropDownHolder{
            padding: 8px 18px;
            background-color: #FFFDFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            a{
                text-decoration: none;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color:#959595;
                &:last-child{
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
            .addMore{
                color: #F0522B;
            }
        }
       
    }
}
    button.saveNew{
        border-radius: 8px;
         margin:5px;
         text-transform: capitalize;
         width: 100%;
        
     }
     button.saveNew,
     button.saveNew:hover{
        box-shadow: unset;
     }
     button.MuiButton-contained{
         background-color:#F0522B ;
         border: 1px solid #F0522B;
         color: #FFF;
     }
     button.MuiButton-contained:hover{
        background-color:#F0522B ;
        border: 1px solid #F0522B;
        color: #FFF;
     }
     button.MuiButton-outlined{
         border: 1px solid #F0522B;
         color: #000;
     }
     button.MuiButton-outlined:hover{
         color: #FFF;
         background-color:#F0522B ;
     }

