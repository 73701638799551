.main_appbar_container{
    // background-image: linear-gradient(to bottom right, #6E1318 5%, #380306 40%); //sa json ni dapat mahalin oh..
    border-bottom-right-radius: 25px;
    border: none;
    position: relative;

    .overflow-header{
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 50px;
        height: 70px;
        background-color: #380306;
    }

    .ro_head_container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

       .first{
            font-size: 20px;
            font-weight: 700;
            color: white;
        }
        .second{
            font-size: 12px;
            font-weight: 400;
            color: #FCAD48;
        }
        
    }

    .ro_payment_container{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        
        .back{
            color: #FCAD48;
            font-size: 12px;
            font-weight: 400;
        }
        .payment_label{
            font-size: 20px;
            color: white;
            font-weight: 700;
            margin-left: -40px;
        }
    }

 

}
.appTB_container{
    // display: flex;
    // align-items: 'center';
    // flex-direction: row;
    // justify-content: space-between;
    padding: 20px;
    
    // .appTB_line_indicator{
    //     height: 3px;
    //     border-radius: 8px;
    // }
}

.appTB_second_container{
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    
    color: rgba(255, 255, 255, 1);
    div:nth-child(1){
        display: flex;
        align-items: center;
    }
    .image_container{
        background-color: white;
        // height: 60px;
        // width: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
    }
    
    .appTB_label_container{
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 700;
        .first{
            margin-left: 20px;
            font-weight: 400;
            margin-right: 10px;
            font-size: 8px;
        }
        .second{
            font-weight:700
        }
    }
    .appTB_guest_container{
        font-size: 12px;
        font-weight: 700;
    }
}


@media (max-width:440px) {
    .appTB_second_container{
        img{
            width: 50% ;
        }
        div:nth-child(1){
            // display: flex;
            // align-items: flex-start;
            // flex-direction: column;
            width: 70%;
        }
        .appTB_label_container{
            
            .first{
                font-size: 9px;
                margin-left: 7px;
                margin-right: 5px;
            }
            .second{
                font-size: 9px;
            }
        }
    }
    .appTB_guest_container{
       width:30% ;
       text-align: right;
    }
    
}
@media (max-width:320px) {
    .appTB_second_container{
        img{
            width: 70% ;
        }
        div:nth-child(1){
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 70%;
        }
        .appTB_label_container{
            
            .first{
                font-size: 9px;
                margin-left: 0;
                margin-top: 5px;
            }
            .second{
                font-size: 9px;
                margin-top: 5px;
            }
        }
    }
}


.cm_body {
    position: relative;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 35px;
    border-bottom-right-radius: 40px;
    z-index: 5;
    // background-color: #6E1318;

.cm_body_label{
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}
.cm_status_label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 100px;
    padding: 10px 24px 10px 24px;
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 1);
    .cm_status_label_left{
        display: flex;
        align-items: center;
        font-size: 12px;
        .first{
            font-weight: 400;
        }
        .second{
            font-weight: 700;
            margin-left: 10px;
        }
    }
    .cm_status_label_right{
        display: flex;
        align-items: center;
        .first{
            font-weight: 400;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-image: linear-gradient(90deg,rgb(255, 191, 73) 20%,rgb(218, 131, 0) 80%) ; 
        }
        .second{
            font-weight: 700;
            margin-left: 8px;
            text-decoration: underline;
            font-size: 12px;
        }
    }
}

 }