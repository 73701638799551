#restoLoginPage{
    height: 100vh;
    .my_container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-color: #000000;
        padding: 50px 0px;
        gap: 150px;
        height: inherit;
        .imageLogo{
            width: 100%;
            max-width: 320px;
        }
        .content{
            width: 100%;
            max-width: 60%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            .title{
                font-family: Inter;
                font-size: 24px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #ffffff;
            }
            input{
                width: 100%;
                max-width: 320px;
                height: 48px;
                padding: 8px 16px 8px 16px;
                border-radius: 8px;
                gap: 8px;
                border: 1px solid #cccccc;
                border-radius: 10px;
            }
            .btnLogin{
                width: 100%;
                max-width: 320px;
                height: 48px;
                padding: 8px;
                border-radius: 8px;
                gap: 8px;
                background-color: #F0522B;
                color: #ffffff;
                text-transform: capitalize;
            }
            .signup{
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                color: #ffffff;
                a{
                    color: #FFE500
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .my_container{
            padding: 50px 20px !important;
            .content{
                max-width: 90% !important;
            }
        }
    }
}
