.order_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EEEAE9;
    min-height: 95vh;
    height: auto;
    position: relative;
    .first{
        color: #2A0003;
        font-size: 24px;
        margin-top: 40px;
        font-weight: 600;
        line-height: 36px;
    }
    .second{
        color: #2A0003;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }
    .label2{

        .third{
            color: #2A0003;
            font-size: 18px;
            margin-top: 20px;
            font-weight: 400;
            text-align: center;
            line-height: 16px;
        }
        .fourth{
            color: #2A0003;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            line-height: 24px;
        }
    }
    .button_container{
        width: 35%;
        max-width: 1440px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        align-items: center;
        padding: 40px;
        background-color: white;
        border: 1px solid #F0522B;
        border-radius: 8px;
        @media (max-width:840px) {
            width: 90%;
         
        }
        @media (max-width:540px) {
            // position: absolute;
            // bottom: 20px;
            margin-bottom: 20px;
        }
    }
    .qr_container{
        width: 200px;
        height: 200px;
        border-radius: 8px;
        background-color: white;
        padding: 20px; 
        margin-top: 30px;
    }
}