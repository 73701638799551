.table_page_container{
    min-height: 90vh;
    background-color: #EEEAE9;
    padding-top: 20px;
    .column_header_container{
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 30px;
        .column_cat{
            padding: 5px 15px;
            border-radius: 5px;
            // background-color: #380306;
            color: white;
            font-size: 12px;
        }
    }
    .content_container{
        padding-left: 20px;
        padding-right: 20px;
    }
    .table_container{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        max-height: 70vh;
        overflow: auto;
        background-color: white;
        padding: 20px;
        justify-content: center;
        .card{
            background-color: #EEEAE9;
            height: 90px;
            max-width: 90px;
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

}