#AppPager{
    .container{
        display: flex;
        flex-direction: row;
        gap: 5px;
        .active{
            border-radius: 2px;
            background-color: #F0522B;
            color: #ffffff;
        }
        a{
            width: 27px;
            height: 34px;
            padding: 10px;
            text-decoration: unset;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: center;
            color: #0F0001;
        }
    }
}