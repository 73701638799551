.my_container{
    background-color: #E9E9E9;
    height: auto;
    min-height: 100vh;
    // padding-bottom: 20px;
    .ro_indicator{
        background-color: #E9E9E9;
        width: 100%;
        position: relative;
        z-index: 10;
        border-top-left-radius: 40px;
        height: 55px;
    }
}



.ro_secondary_container{
    
    padding:20px;
    position: relative;
    margin-top: -50px;
    z-index: 20;
    .btn-custom_order{ //backgroundColor:"#F0522B",width:"70%",borderRadius:"8px",position:'absolute'
       background-color: "#F0522B";
       width: 100%;
       border-radius: 8px;
    //    position: sticky;
    //    margin-left: auto;
    //    margin-right: auto;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
       
    }
  

    .ro_total{
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        background-color: white;
        .ro_label_order{
            font-weight: 700;
            font-size: 16px;
            color: #000000;
        }
        .ro_sub_total{
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: 5px;
            color: #929292;
            justify-content:space-between;
        }
        .ro_dicount_total{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #929292;
            margin-top: 5px;
            justify-content:space-between;
        }
        .ro_vat{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #929292;
            margin-top: 5px;
            justify-content:space-between;
        }
        .ro_total_price{
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #F0522B;
            font-weight: bold;
            margin-top: 8px;
            justify-content:space-between;
            .second{
                color:black
            }
        }
    }

    .ro_container_label{
        display: flex;    
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        div:nth-child(1){
            display: flex;
            align-items: center;
            font-size: 12px;
            .first{
                margin-right: 2px;
                font-weight: 400;
            }
            .second{
                font-weight: 700;
            }
            
        }
        div:nth-child(2){
            display: flex;
            align-items: center;
            font-size: 12px;
            .first{
                margin-right: 2px;
                font-weight: 400;
            }
            .second{
                font-weight: 700;
            }
            
        }
    }

    .pending_order_container{

        .qr_maincontainer{
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            flex-direction: column;
            margin-top: 20px;
        }

        .first_label{
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #7E7E7E;
            margin-bottom: 5px;
        }
        .second_label{
            color: #000000;
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        .third_label{
            color: #000000;
            font-weight: 400;
            line-height: 16px;
            font-size: 16px;
            margin-bottom: 5px;
        }
        .fourth_label{
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        .qr_container{
            padding: 20px;
            background-color: white;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .po_label_container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            
            .first{
                color: #F0522B;
                font-size: 20px;
            }
        }

        .po_content_container{
            border-radius: 8px;
            margin-bottom: 25px;
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;

            .ro_total_price{
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #F0522B;
                font-weight: bold;
                padding: 20px;
                padding-top: 0;
                justify-content:space-between;
                .second{
                    color:black
                }
            }
            .title_only{
                font-size: 12px;
                color: #000000;
                font-weight: 600;
                padding-left: 20px;
                padding-bottom: 2px;
            }
        }


       

    }

    .ro_new_order_container{
        .new_order_label_container{
            margin-top: 20px;
            margin-bottom: 20px;
            .first{
                font-size: 20px;
            }
        }
        .new_order_content_container{
            border-radius: 8px;
            background-color: white;
            margin-bottom: 20px;
            padding-bottom: 5px;
            padding-top: 5px;
            
        }


    }
    
}
