#AppRestoBar{
    width: 100%;
    display: flex;
    justify-content: center;
    .container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        .logo_con{
            padding: 10px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: white;
        }
        .logoImg{
            width: 100%;
            height: 100%;
            background-position: left;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .profileHolder{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            .title{
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: left;
                color: #ffffff;
            }
            .btnLogout{
                padding: 0px 10px;
                background-color: #F0522B;
                font-family: Inter;
                font-size: 10px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #ffffff;
                text-transform: capitalize;
            }
        }
    }
}