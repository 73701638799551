.apc{
    padding: 16px;
    // max-width: 420px;
    background-color: #E9E9E9 !important;
    box-shadow: unset !important;
    border-radius: 24px !important;   
    height: 400px;
    position: relative;
    // height: 700px;
    overflow-y: scroll !important;
    padding-bottom: 100px;
    @media (max-width: 1440px){
        max-width: 300px;
    }
    @media (max-width: 1040px){
        max-width: 280px;
    }
    .apc_seq{
        background-color: #000;
        color: #FFF;
        width: 77px;
        padding: 10px 32px;
        text-align: center;
        font-size: 30px;
        font-weight: 700 !important;
        border-radius: 6px;
        margin: 2px;
        text-align: center;
    }
    .apc_tbl{
        font-size: 22px;
        line-height: 30px;
        margin: 1px;
    }
    .apc_orderID{
        font-size: 17px;
        line-height: 23px;
        color: #FFF;
        padding: 5px 20px;
        margin: 2px;
    }
    .apc_orderList{
        background-color: #FFF;
        padding: 8px;
        border-radius: 10px;
        margin: 8px 0;
        .apc_order{
            font-size: 24px;
            line-height: 28px;
            font-weight: 600 !important;
            color: #000000;
        }
        .apc_orderAddInf{
            color:#636363;
            font-size: 18px;
            line-height: 24px;
            margin-top: 10px;
            display: flex;
            p{
                margin-right: 10px;
            }
        }
        .apc_orderRemarkTitle{
            font-size: 16px;
            color: #000;
            font-weight: 600;
            line-height: 19px;
            margin-top: 10px;
        }
        .apc_orderRemarkDesc{
            background-color: #EEEAE9;
            margin-top: 10px;
            color: #282828;
            font-size: 14px;
            line-height: 18px;
        }
        .apc_order_serve_btn{
            background-color: #1A9126;
            text-transform:capitalize;
            width:100%;
            box-shadow: unset;
            font-size: 14px;
            line-height: 17px;
            border-radius: 8px;
            padding: 8px 16px;
            font-weight: 700;
            border: solid 1px #1A9126;
            margin-top: 10px;
        }
        // .apc_order_serve_btn:hover{
        //     background-color: transparent;
        //     box-shadow: unset;
        //     color:#1A9126 ;
        // }
        
    }
    .apc_orderListCom{
        background-color: rgba($color: #FFF, $alpha: 0.25);
        .apc_order{
            color: #8C8C8C;
        }
        .apc_orderAddInf{
            color:#8C8C8C;
        }
        .apc_orderRemarkTitle{
            color: #8C8C8C;
        }
        .apc_orderRemarkDesc{
            background-color: #EEEAE9;
            margin-top: 10px;
            color: #8C8C8C;
            font-size: 14px;
            line-height: 18px;
        }
        .apc_order_serve_btnComp{
            background-color: rgba($color: #8C8C8C, $alpha: 0.25);
            text-transform:capitalize;
            width:100%;
            box-shadow: unset;
            font-size: 14px;
            line-height: 17px;
            border-radius: 8px;
            padding: 8px 16px;
            font-weight: 700;
            border: solid 1px rgba($color: #8C8C8C, $alpha: 0.25);
            margin-top: 10px;
        }
        .apc_order_serve_btnComp:hover{
            box-shadow: unset;
            background-color: rgba($color: #8C8C8C, $alpha: 0.25);
            color:#FFF ;
        }
    }
    .apc_com_btn{
        background-color: #F0522B;
        text-transform: capitalize;
        box-shadow: unset;
        font-size: 16px;
        border-radius: 8px;
        padding: 8px;
        font-weight: 700;
        border: solid 1px #F0522B;
        width: 100%;
        // margin-top: 20px;
    }
    .complete_order{
        margin-top: 20px;
    }
    .show_more,.show_more:hover{
        background-color: transparent;
        border: unset;
        color: #f0522b;
    }
    .more{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        padding: 10px 10px;
        background: #e9e9e9;
    }
    // .apc_com_btn:hover{
    //     background-color: transparent;
    //     box-shadow: unset;
    //     color:#F0522B ;
    // }
}
.more{
    height:unset !important;
}

@media  screen and (max-width: 1900px) {
    .apc{
        .apc_seq{
            font-size: 22px;
            width: 50px;
            padding: 10px;
        }
        .apc_tbl{
            font-size: 18px;
        }
        .apc_orderID{
            font-size: 15px;
        }
        .apc_orderList{
            .apc_order{
                font-size: 20px;
            }
            .apc_orderAddInf{
                font-size: 16px;
            }
            .apc_orderRemarkTitle{
                font-size: 15px;
            }
            .apc_orderRemarkDesc{
                font-size: 13px;
            }
        }
    }
}
@media  screen and (max-width: 1630px) {
    .apc{
        .apc_seq{
            font-size: 20px;
            width: 45px;
            padding: 10px;
        }
        .apc_tbl{
            font-size: 14px;
        }
        .apc_orderID{
            font-size: 13px;
        }
    }
}
@media  screen and (max-width: 1440px) {
    .apc{
        .apc_seq{
            font-size: 18px;
            line-height: 25px;
            width: 40px;
        }
        .apc_orderID{
            padding: 5px 8px;
            font-size: 10px;
        }
        .apc_orderList{
            .apc_order{
                font-size: 18px;
                line-height: 20px;
            }
            .apc_orderAddInf{
                font-size: 15px;
            }
            .apc_orderRemarkTitle{
                font-size: 14px;
            }
        }
    }
}
@media  screen and (max-width: 1280px) {
    .apc{
        .apc_seq{
            font-size: 18px;
            line-height: 23px;
            width: 35px;
        }
        .apc_orderID{
            padding: 10px 5px;
        }
    }
}
// @media  screen and (max-width: 1100px) {}
//@media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
@media  screen and (max-width: 640px) {
    .apc{
        .apc_seq{
            font-size: 17px;
            line-height: 20px;
            width: 25px;
            padding: 5px;
        }
        .apc_orderID{
            padding: 10px 5px;
        }
    }
}
// @media  screen and (max-width: 575px) {}
// @media  screen and (max-width: 440px) {}
@media  screen and (max-width: 380px) {
    .apc{
        .apc_seq{
            font-size: 15px;
        }
        .apc_tbl{
            font-size: 13px;
        }
        .apc_orderID{
            font-size: 11px;
        }
    }
}
// @media  screen and (max-width: 320px) {}