// .app_loader_container{
//     position: absolute;
//     width: 100%;
//     min-height: 100vh;
//     height: auto;
//     z-index: 100;
//     bottom: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // background-color: rgba(0,0,0,.3);
// }

.loader_container{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0,.7);
    overflow: hidden;
    z-index: 25;

    .lottie_container{
        width: 400px;
        height: 400px;
    }
}