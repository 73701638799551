.items_container{
    //    margin-top: 10px;
       background-color: white;
       height: 100%;
    //    border-radius: 8px;
       padding: 20px;

        ._per_item_container{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .start{
                width: 55%;
                font-size: 12px;
                font-weight: 700;
                padding-right: 10px;
                @media (max-width: 320px) {
                    width: 45%; // Adjust the width for smaller screens
                    padding-right: 0;
                    font-size: 10px;
                }
            }
            .mid{
                width: 20%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                @media (max-width: 320px) {
                    width: 35%; // Adjust the width for smaller screens
                    padding-right: 0;
                    font-size: 12px;
                }
                .first{
                   margin-right: 10px;
                //    padding-top: 2px;
                }
                .second{
                    margin-right:10px;
                    margin-top:2px;
                }
               
            }
            .end{
                width: 30%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                // background-color:red;
                .first{
                    margin-right: 5px;
                }
                // justify-content: space-around;
            }
           
           
        }
        .extra_order_container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
            .first{
              color: #636363;
              font-size: 10px;
              font-weight: 400; 
              width: 35%; 
            }
            .mid{
                color: #636363;
                font-size: 10px;
                font-weight: 400;  
              }
            .second{
                margin-right: 30px;
                font-size: 10px;
                font-weight: 600;  
              }
              
        }
        .change_food{
            margin-top: 7px;
            font-size: 8px;
            text-decoration: underline;
            color: #F0522B;
        }
    }