#account_plan{
    // padding: 10px 0;
    .ck-editor__editable {
        padding: 0 40px;
    }
    figure.ck-widget{
        margin-left: 0;
        margin-right: 0;
    }
    .my_container{
        padding: 10px 20px;
        .restaurant_gallery{
            flex-wrap: wrap;
            align-items:center;
            justify-content: flex-start;
            .imgGal{
                height: 200px;
                max-width: 200px;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: relative;
                margin-right: 10px;
                margin-top: 10px;
                @media (max-width:540px) {
                    height: 120px;
                    max-width: 120px;
                }
                svg{
                    position: absolute;
                    background-color: #f0522b;
                    border-radius: 10px;
                }
            }
            
        }
       
        .plan_con{
             width: 100%;
             max-width:950px;
             margin: 10px auto 0;
             padding: 24px;
             box-shadow: unset;
             border-radius: 14px;
             .heading{
                font-size: 20px;
                line-height: 23px;
                color: #252525;
             }
             .price{
                color: #252525;
                font-size: 24px;
                margin-top: 5px;
             }
             .btns{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 10px;
                button{
                    font-size: 14px;
                    margin:5px;
                    box-shadow: unset;
                    text-transform: capitalize;
                    font-weight: 600 !important;
                }
                // button:first-child{
                //     margin-left: 0 !important;
                // }
                button.MuiButton-contained{
                    background-color:#F0522B ;
                    border: 1px solid #F0522B;
                    color: #FFF;
                }
                button.MuiButton-contained:hover{
                    background-color: transparent;
                    color: #F0522B;
                }
                button.MuiButton-outlined{
                    border: 1px solid #F0522B;
                    color: #000;
                }
                button.MuiButton-outlined:hover{
                    color: #FFF;
                    background-color:#F0522B ;
                }
             }
             .desc_lists{
                li{
                    padding-left: 0;
                    padding-right: 0;
                    .MuiListItemIcon-root{
                        min-width: 0px;
                        margin-right: 10px;
                        svg{
                            color: #F0522B;
                            font-size: 30px;
                        }
                    }
                    .MuiListItemText-root{
                        font-size: 12px;
                        color: #252525;
                    }
                    
                }
             }
            .change_btn{
                box-shadow: unset;
                width: 100%;
                background-color: #F0522B;
                border: 1px solid #F0522B;
                color: #FFF;
                text-transform: capitalize;
                padding: 10px 20px;
                border-radius: 8px;
            }
            .change_btn:hover{
                color: #F0522B;
                background-color: unset;
            }
        }
    }
    .acctn_settings{
        width: 100%;
        max-width:950px;
        margin: 25px auto 0;
        padding: 24px;
        box-shadow: unset;
        border-radius: 14px;
        .heading{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            b{
                font-size: 20px;
                line-height: 23px;
                color: #252525;
            }
            .edit{
                color: #F0522B;
                text-decoration: underline;
            }
           
        }
        .form_fields{
            background-color: #FFF;
            border-radius: 8px;
            padding: 5px 10px;
            color: red;
            fieldset{
               border-width: 0;
            }
        }
        .save_btn{
            margin-top: 15px;
            box-shadow: unset;
            width: 100%;
            background-color: #F0522B;
            border: 1px solid #F0522B;
            color: #FFF;
            text-transform: capitalize;
            padding: 10px 20px;
            border-radius: 8px;
            font-size: 16px;
        }
    }
    .setting_label{
        color: #2A0003;
        font-size: 24px;
        font-weight: 700;
        margin-top: 40px;
        margin-bottom: 20px;
        line-height: 24px;
    }
    .landing_page_setting{
        padding: 20px;
        border-radius: 10px;
        // margin-top: 50px;

        .container{
            
            .preview{
                padding: 20px;
                border-radius: 10px;
                margin-bottom: 20px;
                border: 1px solid whitesmoke;


                .off{
                    font-weight: 700;
                    font-size: 18px;
                }
                .header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px;
                }
                .listing{
                    font-size: 10px;
                    margin-top: 10px;
                    color: #B5B5B5;
                }
            }


            .label_1{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .promotion{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #2A0003;
                }   
                .edit{
                    color: #F0522B;
                    font-weight: 400;
                    font-size: 14px;
                    text-decoration: underline;
                }
            }

            .label_2{
                margin-top: 20px;
                .promotion{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #2A0003;
                    margin-bottom: 5px;
                }   
                .edit{
                    color: #F0522B;
                    font-weight: 400;
                    font-size: 14px;
                    text-decoration: underline;
                }
            }

            .clocks{
                margin-top: 10px;
                .promotion{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #2A0003;
                    margin-bottom: 5px;
                }   
            }


            .imgSection{
                display: flex;
                flex-direction: column;
                gap: 5px;
                .imgTitle{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #959595;
                    margin-bottom: 5px;
                }
                .imgHolder{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    .modalImgProd{
                        width: 86px;
                        height: 50px;
                        border-radius: 4px;
    
                    }
                    .changeImage{
                        border: 1px dashed  #C7C7C7;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        padding: 10px;
                        margin-bottom: 10px;
                        .imgIcon{
                            width: 44px;
                            height: 44px;
                            border-radius: 10px;
                            display: flex;
                            background-color: #EEEAE9;
                            justify-content: center;
                            align-items: center;
                            // background-color: #EEEAE9;
                            color: #F0522B;
                        }
                        .imgContentHolder{
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            .changeLabel{
                                font-family: Montserrat;
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 15px;
                                letter-spacing: 0em;
                                color: #F0522B;
                                text-decoration: none;
                            }
                            .changeDesc{
                                font-family: Montserrat;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 15px;
                                letter-spacing: 0em;
                                color: #B5B5B5;
                            }
                        }
                    }
                }
            }
        }
    }
}


// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
// @media  screen and (max-width: 575px) {}
@media  screen and (max-width: 440px) {
    #account_plan{
        .my_container{
            padding: 5px 15px 20px;
            .plan_con{
                padding: 15px;
                .btns{
                    button{
                        margin: 5px;
                    }
                }
            }
        }
    }
}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}