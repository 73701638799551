// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
// @media  screen and (max-width: 575px) {}
// @media  screen and (max-width: 440px) {}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.openSans{
  font-family: "Open Sans", sans-serif !important;
}
.inter{
  font-family: "Inter", sans-serif !important;
}
body {
  margin: 0;
}

#root{
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  // overflow-y: auto; 
}

.my_container {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  // padding: 0 5px;
}
iframe#webpack-dev-server-client-overlay{
 display: none;
}

label + .MuiInput-formControl { 
  margin-top: 0;
}
@media (hover: none){
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
}
}

.css-bhp9pd-MuiPaper-root-MuiCard-root{
  width: 100%;
}

.css-4i63cr-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 10px;
}

.MuiInputBase-input{
  padding: 10px !important;
}
.MuiInput-root::after,
.MuiInput-root::before{
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 15mm;
}