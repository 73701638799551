.kitchen_container{
    width: 100%;
    max-width: 95vw;
    margin: 0 auto;
    padding: 0 10px;
}

#kitchen_header{
    background-color: #191919;
    padding: 35px 0;

    .station{
        width: 200px;
        padding-left: 15px;
        margin-bottom: 15px;
        p{
            color: white;
            letter-spacing: 1px;
            margin-bottom: 5px;
        }
    }
    .kitchen_container{
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .logo_con{
            flex-direction: row;
            display: flex;
            align-items: center;
            .client_logo{
                width:208px ;
            }
            .logo_con{
                width:120px ;
                height: 120px;
                background-color: #FFF;
                border-radius: 100%;
                padding: 10px;
                .logoImg{
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .poweredby{
                width: 82px;
                margin-left: 10px;
            }
        }
        .time_order_con{
            flex-direction:row;
            display: flex;
            align-items:center;
            .time {
                color: #FFF;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                b{
                    font-size: 24px;
                    margin-left: 5px;
                }
            }
            .active_order{
                color: #FFF;
                margin-left: 10px;
                font-size: 18px;
                border: 1px solid #1A9126;
                padding: 10px 32px;
                border-radius: 6px;
            }
        }
    }
   
}

#kitchen_order{
    padding: 50px 0;
    background-color: #000000;
    .kitchen_container{
        padding: 0 20px;
        .nowPrep{
            color: #FFF;
            font-size: 48px;
            margin-bottom: 20px;
        }
    }
}




// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
@media  screen and (max-width: 980px) {
    #kitchen_header{
        padding: 20px 0;
        .kitchen_container{
            .logo_con{
                .client_logo {
                    width: 120px;
                  }
            }
            .time_order_con{
                .time {
                    font-size: 14px;
                    b{
                        font-size: 16px;
                    }
                }
                .active_order {
                    font-size: 14px;
                    padding: 10px 20px;
                    border-radius: 5px;
                  }
            }
        }
    }
    #kitchen_order{
        padding: 40px 0;
        .kitchen_container{
            .nowPrep {
                font-size: 35px;
                margin-bottom: 20px;
              }
        }
    }
}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
@media  screen and (max-width: 575px) {
    #kitchen_header{
        padding: 15px 0;
        .kitchen_container{
            .logo_con{
                .client_logo {
                    width: 100px;
                  }
            }
            .time_order_con{
                .time {
                    font-size: 13px;
                    
                    b{
                        font-size: 14px;
                    }
                }
                .active_order {
                    font-size: 13px;
                    padding: 10px 15px;
                  }
            }
        }
    }
    #kitchen_order{
        padding: 20px 0;
        .kitchen_container{
            .nowPrep {
                font-size: 25px;
                margin-bottom: 15px;
              }
        }
    }
}
@media  screen and (max-width: 500px) {
    #kitchen_header{
        .kitchen_container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
    }
}
// @media  screen and (max-width: 440px) {}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}