#AppSearch{
    width: 100%;
    .container{
        display: flex;
        align-items: center;
        height: 48px;
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        border: 1px solid #959595;
        gap: 8px;
        .searchIcon{
            font-size: 20px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-content: center;
            color: #959595
        }
        .searchBar{
            padding: 5px;
            border: 0px;
            background-color: unset;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            width:100%;
            color: #959595;
            &:focus-visible{
                outline: 0;
            }
        }
    }
}