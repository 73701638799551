.ty_container{
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        background-size: cover;

        .mid{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: -150px;
            img{
                width: 143px;
                height: 58px;
            }
            .desc{
                padding:20px 30px 15px 30px;
                text-align: center;
                font-size: 20px;
                font-style: italic;
                color: white;
            }
            .btn_container{
                padding: 10px;
                border-radius: 6px;
                background-color: #911A20;
                font-size: 16px;
                min-width:176px;
                text-align: center;
                .first{
                    font-weight: 400;
                    color: white;
                }
                b{
                    color: white;
                    font-weight: 700;
                }
            }
        }
        
        .ty_footer{
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding:5px 20px 5px 20px;
            .save_text{
                color:white;
                font-size:16px;
                margin-bottom: 10px;
                display:flex;
                align-items: center;
            }
        }
}