.appTB_line_indicator{
    height: 3px;
    border-radius: 8px;
}
.indicator_container{
    display: flex;
    align-items: 'center';
    flex-direction: row;
    justify-content: space-between;
    z-index:10
}