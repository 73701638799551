#routes{
    .routes-header{
        .my_container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: unset;
            .nav{
                display: flex;
                flex-direction: row;
                gap: 20px;
                @media (max-width:440px) {
                    gap: 10px;
                }
                .active{
                    border-bottom: 5px solid #F0522B;
                }
                .menuHolder{
                    height: 38px;
                    gap: 5px;
                    .menu{
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        text-decoration: none;
                        color: #ffffff;
                        @media (max-width:440px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}