.switch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    .customSwitch{
        .react-switch-bg{
            height: 15px !important;
        }
        .react-switch-handle{
            top: -6px !important;
        }
    }
    .title{
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        color: #2A0003;
    }
}#AppRestoSubMenu{
    width: 100%;
    .label{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:#959595;
        margin-top: 15px;
    }
    .inputField{
        border: 0px;
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        border-radius: 8px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        background-color: #EEEAE9;
        color: #959595;
    }
   
    .subMenuContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        .content-holder{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 10px;
            position: relative;
                .disable{
                    background-color: rgba(255, 255, 255, 0.59);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left:0
                }
            .holder{
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;
            }
            .labelIcon{
                margin-top: 10px;
            }
            .priceHolder{
                width: 30%;
                .label{
                    font-size: 22px;
                }
            }
        }
        .addVariation{
            text-decoration: none;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: #F0522B;
        }
    }
    .timePrep, .servings, .sendTo{
        .label{
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #2A0003;
        }
    }
    .timePrep{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .TimePrepContent{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            .dash{
                background-color: #2A0003;
                width: 10px;
                height: 4px;
                margin-top: 20px;
            }
            .TimePrepHolder{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 5px;
                // width: 50%;
                .time{
                    padding: 8px 16px;
                    border-radius: 8px;
                    background-color: #EEEAE9;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 0em;
                    color: #313131;
                    width: 100%;
                    border: unset;
                }
            }
        }
    }
    .servings{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .servingsContent{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .servingNumber{
                width: 50%;
                height: 50px;
                background-color: #EEEAE9;
                display: flex;
                align-items: center;
                padding: 8px 16px;
                border-radius: 8px;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #959595;
            }
            .servingPersons{
                display: flex;
                align-items: center;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #959595;
            }
        }
    }
    .sendTo{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    @media screen and (max-width: 480px) {
        .inputField{
            height: 40px !important;
            padding: 6px 12px !important;
            font-size: 12px !important;
        }
        .subMenuContent{
            .content-holder{
                .holder{
                    width: 50%;
                    gap: 4px;
                    .label{
                        font-size: 12px;
                        margin-top: 10px;
                    }
                    .labelIcon{
                        font-size: 22px;
                    }
                }
                .priceHolder{
                    width: 50%;
                }
            }
        }
        .timePrep{
            .TimePrepContent{
                .TimePrepHolder{
                    .time{
                        font-size: 12px;
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}