.my_container2{
    height: 100vh;
     background-color: #E9E9E9;
    .fp_secondary_container{
        position: absolute;
        top: 70px;
        width: 100%;
    }
    .fp_indicator{
        background-color: #E9E9E9;
        height: 60px;
        width: 70px;
        // z-index: 2;
        position: relative;
        border-top-left-radius: 50px;
    }
}

.fp_main_container{
    display: flex;
    padding: 20px;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top:20px;
    .back{
        color: #FCAD48;
        font-size: 12px;
        font-weight: 400;
    }
    .payment_label{
        font-size: 20px;
        color: white;
        font-weight: 700;
        margin-left: -50px;

        @media (max-width:540px) {
            max-width: 70%;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
        }
    }
}

.order_added{
    font-size: 16px;
    color: #E71924;
    font-style: italic;
    font-weight: 700;
    text-align: center;
}

.fp_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;

    .best_seller_badge{
        background-image: linear-gradient(#FCAD48,#F0522B);
        width: 84px;
        text-align: center;
        font-size: 10px;
        color: white;
        padding: 2px;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .fp_price{
        font-size:48px;
        font-weight:700;
        color:#FFFFFF;
        margin-bottom:10px
    }
    img{
        width: 218px;
        height:220px;
        border-radius: 50%;
    }
    .detail_container{
        padding: 20px;
        font-size: 14px;
        background-color:#E9E9E9;
        width: 100%;
      
        .first{
            color: #606060;
            padding-bottom: 10px;
            padding: 10px;
            text-align: start;
            b{
                color: black;
            }
        }

        .add_ons{
            display: flex;
            justify-content: space-between;
            flex-wrap:wrap;
            padding-left: 10px;
            padding-right: 10px;
        }
        .note_container{
            margin-top:20px  ; 
            
            .first{
               padding-bottom: 10px;
            }
        }

        ._per_item_container{
            width: 100%;
            display: flex;
            justify-content: center;
            

            .mid{
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: -10px;

                @media (max-width: 320px) {
                    width: 40%; 
                }

                .first{
                    font-size: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;
                    // margin-right: 10px;
                }
                .second{
                    font-size: 20px;
                    // background-color: green;
                }
            }
          
           
        }
    }
}