.typo{
    padding: 10px 14px;
    height: 45px;
}
.changeImage_{
    border: 1px dashed  #C7C7C7;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    position: relative;
    .imgIcon{
        width: 44px;
        height: 44px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #EEEAE9;
        color: #F0522B;
    }
    .imgContentHolder{
        display: flex;
        flex-direction: column;
        gap: 5px;
        .changeLabel{
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            color: #F0522B;
            text-decoration: none;
        }
        .changeDesc{
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: #B5B5B5;
        }
    }
}
.form_fields{
    background-color: #EEEAE9;
    border-radius: 8px;
    fieldset{
    border-width: 0;
    }
}
.theme_img{
    width: 100%;
    max-width: 250px;
}
button.apply_theme{
    margin-top: 10px;
    width: 100%;
    max-width: 308px;
    text-transform: capitalize;
}
button.apply_theme.MuiButton-contained{
    background-color:#F0522B ;
    border: 1px solid #F0522B;
    color: #FFF;
    box-shadow: unset;
}
button.apply_theme.MuiButton-contained:hover{
    background-color: transparent;
    color: #F0522B;
}
button.apply_theme.MuiButton-outlined{
    border: 1px solid #F0522B;
    color: #000;
}
button.apply_theme.MuiButton-outlined:hover{
    color: #FFF;
    background-color:#F0522B ;
}
#setting{
    // padding: 10px 0;
    .my_container{
        padding: 10px 20px;
        .restaurant_settings{
            width: 100%;
            max-width:950px;
            margin: 25px auto 0;
            padding: 24px;
            box-shadow: unset;
            border-radius: 14px;
            .modalImgProd{
                position: absolute;
                z-index: 1;
                right: 0;
                // height: 100%;
                width: 100px;
                margin-top: 10px;
                height: 50px;
                background-size: contain;
                background-position: right;
                background-repeat: no-repeat;
            }
            .heading{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                flex-wrap: wrap;
                b{
                    font-size: 20px;
                    line-height: 23px;
                    color: #252525;
                }
                .edit{
                    color: #F0522B;
                    text-decoration: underline;
                }
            
            }
            
            .save_btn{
                margin-top: 25px;
                box-shadow: unset;
                width: 100%;
                background-color: #F0522B;
                border: 1px solid #F0522B;
                color: #FFF;
                text-transform: capitalize;
                padding: 10px 20px;
                border-radius: 8px;
                font-size: 16px;
            }
        }
    }
    .MuiAccordion-root{
        box-shadow: unset;
        .MuiAccordionDetails-root {
            border: 1px solid #EEEAE9;
            padding: 0;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            p{
                padding: 15px 25px;
                color: #959595;
                width: 100%;
            }
        }
    }
    .MuiAccordion-root.Mui-expanded{
        margin-top: 0;
    }
    .MuiAccordion-root::before{
        display: none;
    }
    .app_accordion{
        background-color: #EEEAE9;
        color: #959595;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-top: 10px;
        font-family: Montserrat,sans-serif;
        width: 100%;
        padding: 10px;
    }
    .table_settings{
        width: 100%;
        max-width:950px;
        margin: 25px auto 0;
        padding: 24px;
        box-shadow: unset;
        border-radius: 14px;
        .table_container{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            box-shadow: 0 2px 7px rgb(231, 225, 222);
            padding: 10px;
            border-radius: 10px;

            .branch_name_container{
                display: flex;
                align-items: center;
                .first{
                    width: 50%;
                }
                .branch_name_text{
                    text-transform: capitalize;
                    font-weight: 700;
                }
            }
            .cat_container{
                margin-top: 10px;
                background-color: rgb(250, 247, 247);
                padding: 10px;
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 10px;
                position: relative;
                div {
                    display: flex;
                    align-items: center;
                }
                .lab{
                    width: 50%;
                }
                .lab_value{
                    text-transform: capitalize;
                    font-weight: 700;
                }
            }
        }
        .heading{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            b{
                font-size: 20px;
                line-height: 23px;
                color: #252525;
            }
            .add_table{
                color: #F0522B;
                text-decoration: underline;
            }
        
        }
        .print_Qr{
            padding: 8px 10px;
            margin-top: 10px;
            font-size: 16px;
        }
        button{
           border-radius: 8px;
            margin:5px;
            box-shadow: unset;
            text-transform: capitalize;
            width: 100%;
        }
        button.MuiButton-contained{
            background-color:#F0522B ;
            border: 1px solid #F0522B;
            color: #FFF;
        }
        button.MuiButton-contained:hover{
            background-color: transparent;
            color: #F0522B;
        }
        button.MuiButton-outlined{
            border: 1px solid #F0522B;
            color: #000;
        }
        button.MuiButton-outlined:hover{
            color: #FFF;
            background-color:#F0522B ;
        }
        .table{
            padding: 16px;
            margin-top: 15px;
            border-radius: 8px;
            box-shadow: unset;
            border: 1px solid #CFCFCF;
            .field_lbl{
                color: #212121;
                font-size: 14px;
                margin-bottom: 10px;
            }
           
        }
    }   

    .staff_settings{
        width: 100%;
        max-width:950px;
        margin: 25px auto 0;
        padding: 24px;
        box-shadow: unset;
        border-radius: 14px;
        .heading{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            b{
                font-size: 20px;
                line-height: 23px;
                color: #252525;
            }
            .add_table{
                color: #F0522B;
                text-decoration: underline;
            }
        
        }
    }
}

.MuiAccordion-root{
    box-shadow: unset;
    .MuiAccordionDetails-root {
        border: 1px solid #EEEAE9;
        padding: 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        p{
            padding: 15px 25px;
            color: #959595;
            width: 100%;
        }
    }
}
.MuiAccordion-root.Mui-expanded{
    margin-top: 0;
}
.MuiAccordion-root::before{
    display: none;
}
.app_accordion2{
    background-color: #EEEAE9;
    color: #959595;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif;
    width: 100%;
}

.restaurant_gallery{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}