.footer_container{
        max-width: 1200px;
        width: 100%;
        .secondary_container{
            width: 1200px;
            // background-color: red;
            height: 1010px;
            margin-top: 289px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .first{
                display: flex;
                align-items: center;

                .left_side{
                    width: 60%;
                    height: 442px;
                    background-color: #020202;
                    padding: 40px;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    padding-right: 70px;
                    .first{
                        color: #F0522B;
                        font-weight: 700;
                        font-size: 16px;
                        margin-bottom: 10px;
                        line-height: 16px;
                    }
                    .second_{
                        color: #FFFFFF;
                        font-weight: 400;
                        font-size: 42px;
                         line-height: 50px;
                         text-transform: capitalize;
                         
                    }
                    .third{
                        color: #FFFFFF;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;
                        margin-top: 20px;
                    }
                }
                .right_side{
                    width: 40%;
                    height: 442px;
                    position: relative;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    
                }
            }

            .second{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 200px;

                .logo_container{
                    width: 201px;
                    height:87px;
                }
                .cutting_edge{
                    color: #8B8B8B;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .list{
                    display:flex;
                    align-items: center;
                    width: 519px;
                    justify-content: space-between;
                    color: #2B2B2B;
                    font-size: 24px;
                   .list_item{
                    font-weight: 700;
                   }
                }    

                .watermark{
                    color: #000000;
                    opacity: .5;
                    margin-top: 50px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 22px;
                }
            }
          
        }
}