.my_container{
    position: relative; /* Set position to relative for absolute positioning of overlay */
    height: 100vh;
    width: 100%;
    background-size:auto;
    padding: 0;
    
  }
.splash_container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-size: cover;
}
.splash_comp_logo{
    width: 244px;
    height: 67px;
    background-size: contain;
    background-repeat: no-repeat;
}
.splash_underline{
    height: 2px;
    background: rgba(240, 82, 43, 1);
    width: 180px;
}
.splash_footer{
    position: absolute;
    bottom: 20px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    font-size: 10px;
    font-size: 700;
    // width: ;
   .first{
        margin-right: 3px;
        font-weight: 400;
    }
}

@media (max-width:320px) {
    .splash_comp_logo{
        width: 90%;
        height: 60px
    }
    .splash_underline{
        width: 70%;
    }
}