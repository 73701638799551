#restoMainPage{
    position: relative;
    //background-color: #3b3b3b;
    .my_container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .content-container{
            padding: 10px 20px;
            flex-direction: column;
            background-color: #EEEAE9;
            display: flex;
            gap: 10px;
            overflow-y: scroll;
            .content{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
            }
        }
        
    }
}

.modalFooter{
    display: flex;
    flex-direction: column;
    gap: 10px;
    button{
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        padding: 15px 0px;
    }
    .btnSave{
        background-color: #F0522B;
        color: #ffffff;
        text-transform: capitalize;
        box-shadow: unset;
    }
    .btnDelete{
        border-color: #F0522B;
        color: #F0522B;
        text-transform: capitalize;
        box-shadow: unset;
    }
}

.timePrep, .servings, .sendTo{
    .label{
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #2A0003;
    }
}
.timePrep{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    .TimePrepContent{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
        .dash{
            background-color: #2A0003;
            width: 10px;
            height: 4px;
            margin-top: 20px;
        }
        .TimePrepHolder{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 5px;
            justify-content: space-evenly;
            // width: 50%;
            @media (max-width:767px) {
                flex-wrap: wrap;
            }
            >div{
                width: 45%;
                @media (max-width:525px) {
                    width: 100%;
                }
            }
            .time{
                padding: 8px 16px;
                border-radius: 8px;
                background-color: #EEEAE9;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 700;
                line-height: 29px;
                letter-spacing: 0em;
                color: #313131;
                width: 100%;
                border: unset;
                @media (max-width:767px) {
                    line-height: 20px;
                }
            }
        }
    }
}
.servings{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    .servingsContent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .servingNumber{
            width: 50%;
            height: 50px;
            background-color: #EEEAE9;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border-radius: 8px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #959595;
        }
        .servingPersons{
            display: flex;
            align-items: center;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #959595;
        }
    }
}