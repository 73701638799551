
// .my_container{
//   min-height: 100vh;
//   width: 100%;
// height: auto;
// }
// .overlay {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(33, 0, 2, .8); /* Red with 50% opacity */    
// }
.cm_secondary_container{
    height: 100%;
    width: 100%;
    padding: 0;
    // background-image: linear-gradient(to bottom right, #6E1318 5%, #380306 30%);

    
  
  .cm_content_container{
    display: flex;
    // background-color: #380306;
    height: 78.5vh;
    // padding:20px;
    border-top-right-radius: 40px;
    padding-right: 0;
    padding-top: 0;
    margin-top: -1px;
    .cm_content_container_left{
        width: 15%;
        // background-color: #380306;
        // flex: 1;
        // background-color: orange;
    }
    .cm_content_container_right{
        width: 85%;
        background-color: rgba(233, 233, 233, 1);
        border-top-left-radius: 30px;
        position: relative;
        // padding: 20px;
        // background-color: red;
        // overflow: auto;
         max-height: 78.5vh;
         overflow: hidden;
         .cat_tainer{
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            padding-bottom: 30px;
            // margin-right: 5px;
            overflow: auto;
            max-height: 70.5vh;
            @media (max-width:767px) {
                gap: 10px;
            }
            @media (max-width:540px) {
                gap: 0;
            }
        }
        .cm_btn_container{
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 20px;
            width: 75%;
            border-radius: 8px;
        }
       
        // z-index: 1;
        // .fill_border_radius{
        //     height: 20px;
        //     width: 25px;
        //     position: absolute;
        //     top: -1px;
        //     right: 0;
        //     border-top-left-radius: 2px;
        //     background-color: rgba(233, 233, 233, 1);
        //     z-index: 1;
        //     // border-radius: 50%;
        //     // clip-path: polygon(100% 80%, 100% 100%, 81% 100%, 95% 94%);
        // }
    }
}

}