.my_container3{
    background-color: #E9E9E9;
    min-height: 100vh;
    // height: auto;
    .payment_indicator{
        height: 70px;
        width: 100%;
        background-color: #E9E9E9;
        border-top-left-radius: 40px;
        position: relative;
        z-index: 10;
    }
}
.order_secondary_container{
    height: 100%;
    padding: 20px;
    position: relative;
    margin-top: -70px;
    z-index: 20;
    .first{
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
    }
    .second{
        color: #000000;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 15px;
    }
}